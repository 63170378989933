import { version } from './version'

export const environment = {
  BASE_URL: 'https://api.eiconbrasil.com.br/dte-servicos/plataformadte/v2',
  API_URL: "https://prodata.sefaz.to.gov.br/api",
  production: true,
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/'
};
