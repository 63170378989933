<body>
  <section class="descricao">
    <div class="descricao__container">
      <div>
        <img class="descricao__imagem" src="../../../../assets/images/imagens/imagem_home.svg" alt="Imagem da Descrição">
      </div>
      <div>
        <p class="descricao__texto">“O Domicílio Eletrônico do Contribuinte é uma plataforma que tem como objetivo otimizar a relação entre o contribuinte e o Estado. 
          Esta ferramenta simplifica processos burocráticos, mantendo os controles essenciais intactos. 
          Além disso, facilita a comunicação e proporciona ao contribuinte um acesso rápido e eficiente a informações pertinentes.”
        </p>
      </div>
    </div>
  </section>
  <section class=" margem-top member_counter">
    <div class="banner-part" id="desempenho">
      <div class="container-fluid">
        <p class="text-center texto-desempenho mt-5 mb-5 pb-3">Desempenho do Domicílio</p>
      </div>
    </div>
    <div class="container" id="desloc">
      <div class="row">
        <div class="col-lg-6 col-sm-6 comunicados">
          <div class="single_member_counter">
            <span class="counter">{{ this.dadosDesempenho?.comunicados }}</span>
            <h4>Comunicados</h4>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 destinatarios">
          <div class="single_member_counter">
            <span>{{ this.dadosDesempenho?.destinatarios}}</span>
            <h4>Destinatários</h4>
          </div>
        </div>
        <!-- <div class="col-lg-4 col-sm-6">
          <div class="single_member_counter">
            <span>{{ this.dadosDesempenho.eficiencia }}%</span>
            <h4>Eficiência</h4>
          </div>
        </div> -->
      </div>
    </div>
  </section>
  <section class="links">
    <div class="links__container">
      <a href="https://play.google.com/store/apps/details?id=com.dec.tocantins&pli=1" target="_blank">
        <img class="img-link" src="./assets/images/imagens/google-play-badge.png" alt="Link Google Play" title="Aplicativo DEC">
      </a>
    <div class="ml-1">
      <a href="https://apps.apple.com/br/app/dec-domicílio-do-contribuinte/id6472929627" target="_blank">
        <img src="./assets/images/imagens/app-store-fundo-preto.svg" alt="Link Apple Store" title="Aplicativo DEC">
      </a>
    </div>
    </div>
  </section>
  <section class="margem-top margem-bottom servicos">
    <div class="servicos-texto">As informações contidas nos sistemas informatizados da Administração Pública estão
       protegidas por sigilo. Todo acesso é monitorado e controlado. Ao dar continuidade à navegação neste serviço o usuário
       declara-se ciente das responsabilidades penais, civis e administrativas descritas na Política de Privacidade e Uso.
    </div>
  </section>
  <section class="margem-top margem-bottom">
    <p class="texto-suporte">Precisa de Suporte?</p>
  <div class="servicos-container">
    <div class="servicos-item centralizar" (click)="abrirLink()">
      <div class="row d-flex justify-content-center">
        <img 
        class="servicos-item__imagem" 
        src="../../../../assets/images/icones/comments-solid.svg" 
        alt="Balão de conversa">
      </div>
      <div class="row d-flex justify-content-center">
        <div class="servicos-item__texto">FALE CONOSCO</div>
      </div>
    </div>
    <div class="servicos-item centralizar" routerLink="/duvidas-frequentes">
      <div class="row d-flex justify-content-center">
        <img 
        class="servicos-item__imagem" 
        src="../../../../assets/images/icones/question-solid.svg" 
        alt="Balão de conversa">
      </div>
      <div class="row d-flex justify-content-center">
        <div class="servicos-item__texto">DÚVIDAS FREQUENTES</div>
      </div>
    </div>
  </div>
  </section>
</body>
